<template>
    <el-dialog
        :title="$t('deleteEmployee.update picture')"
        :visible="visible"
        top="30vh"
        @close="close()"
        :modal="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :destroy-on-close="true"
    >
        <vueCropper
            ref="cropper"
            mode="contain"
            :img="url"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
            :fixed="fixed"
            :fixed-number="option.fixedNumber"
            style="width: 260px; height: 260px; margin: 0 auto"
        >
        </vueCropper>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()">{{$t('deleteEmployee.Cancel')}}</el-button>
            <el-button type="primary" @click="confirm()">{{$t('deleteEmployee.Confirmz')}}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api";
import { VueCropper } from "vue-cropper";

export default defineComponent({
    name: "Cropper",
    props: {
        title: {
            type: String,
            default: "update picture",
        },
        visible: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: "",
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["close", "confirm"],
    components: {
        VueCropper,
    },
    setup(props, { emit }) {
        const cropper = ref(null);
        const option = ref({
            size: 1,
            full: false,
            outputType: "jpeg",
            canMove: true,
            fixedBox: false,
            original: false,
            canMoveBox: true,
            autoCrop: true,
            // 只有自动截图开启 宽度高度才生效
            autoCropWidth: 130,
            autoCropHeight: 130,
            centerBox: true,
            high: true,
            fixed: true,
            fixedNumber: [1, 1],
        });
        function confirm() {
            cropper.value.getCropBlob((data) => {
                // data blob to file
                const file = new File([data], "cropper.jpg", {
                    type: "image/jpeg",
                });
                emit("confirm", file);
                emit("close", false);
            });
        }
        function close() {
            emit("close", false);
        }
        return { cropper, option, confirm, close };
    },
});
</script>
<style></style>
